<template>
  <div>
    <div class="divier_top" />
    <div class="root">

      <div class="form">

        <div class="form-item top_item_margin">
          <div class="form-item-label">
            <div class="form-item-label-text">{{ $t('center_address') }}</div>
          </div>

          <my-input class="form-item-input" :placeholder="$t('center_address_hint')" disabled v-model="form.account">
          </my-input>
        </div>
        <div class="form-item">
          <div class="form-item-label">
            <div class="form-item-label-text">{{ $t('center_merchant') }}</div>
          </div>

          <my-input class="form-item-input" :placeholder="$t('center_merchant_hint')" v-model="form.name"></my-input>
        </div>
        <div class="form-item">
          <div class="form-item-label">
            <div class="form-item-label-text">{{ $t('center_super') }}</div>
          </div>

          <my-input class="form-item-input" :placeholder="$t('center_super_hint')" v-model="form.superior"></my-input>
        </div>
        <div class="form-item">
          <div class="form-item-label">
            <div class="form-item-label-text">{{ $t('center_bzj') }}</div>
            <div class="form-item-label-text">{{ $t('center_avaliable') }} {{info.balance.div(info.usdtDecimals).decimalPlaces(2) }} USDT</div>
          </div>

          <my-input class="form-item-input" :placeholder="$t('center_bzj_hint')" disabled v-model="form.amount">
            <template slot="suffix">
              <div class="form-item-suffix">USDT</div>
            </template>
          </my-input>
        </div>
        <div class="form-item">
          <div class="form-item-label">
            <div class="form-item-label-text">{{ $t('center_phone') }}</div>
          </div>

          <my-input class="form-item-input" :placeholder="$t('center_phone_hint')" v-model="form.tel"></my-input>
        </div>


      </div>

      <div class="protocol">
        <!-- <el-checkbox v-model="check">
        <p>我已认真阅读并同意</p>
      </el-checkbox> -->
        <van-checkbox v-model="check" icon-size="15px" shape="square">
          <p class="check_label">{{ $t('center_read_agree') }}</p>
        </van-checkbox>
        <a href="javascript:;" class="protocol-a" @click="protocolClick">{{ $t('center_aggrement') }}</a>
      </div>

      <div class="btn">
        <el-button class="btn_left" v-if="!info.approve" @click="approve" :loading="approveLoading">{{ $t('approve') }}
        </el-button>
        <el-button class="btn_right" v-else @click="register" :loading="registerLoading">{{ $t('center_register') }}
        </el-button>
      </div>

      <tips class="tip_container" type="register"></tips>

    </div>
  </div>

</template>

<script>
import Tips from "../components/Tips"
import MyInput from "../components/my-input/input"
import { getAccounts } from "../utils/wallet";
import { Register, RegisterInfo } from "../api/home";
import BigNumber from "bignumber.js";
import { getTraderAddress } from "../utils/addressHelpers";
import { USDTApprove } from "../api/token";
import { isAddress } from "../utils/index";
import { mapActions, mapGetters } from "vuex";
import { MerchantRegister } from "../api/httpApi";
export default {
  name: "TraderRegister",
  components: {
    MyInput,
    Tips,
  },
  computed: {
    ...mapGetters(["superior", "protocol"])
  },
  data() {
    return {
      infoLoading: false,
      info: {
        approve: false,
        balance: new BigNumber(0),
        defaultBail: new BigNumber(0),
        usdtDecimals: new BigNumber(1e18),
      },
      approveLoading: false,
      registerLoading: false,
      check: false,
      form: {
        account: '',
        name: '',
        superior: '',
        amount: '',
        tel: ''
      }
    }
  },
  mounted() {
    this.getAuthorizeInfo().then()
    if (this.superior !== '0x0000000000000000000000000000000000000000') {
      setTimeout(() => {
        this.form.superior = this.superior
      }, 100)
    }
  },
  methods: {
    ...mapActions(["setIsRegister"]),
    async getAuthorizeInfo() {
      const accounts = await getAccounts()
      this.form.account = accounts[0]
      await this.getHomeInfo()
    },
    async getHomeInfo() {
      if (this.infoLoading) {
        return false
      }
      this.infoLoading = true
      this.info = await RegisterInfo(this.form.account)
      this.form.amount = this.info.defaultBail.div(this.info.usdtDecimals).toString()
      this.infoLoading = false
    },
    async approve() {
      if (this.approveLoading) {
        return
      }
      const traderAddress = getTraderAddress()
      this.approveLoading = true
      const res = await USDTApprove(traderAddress)
      this.approveLoading = false
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: this.$t('center_approve_cancel'),
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      this.$notify({
        type: 'success',
        message: this.$t('center_approve_success'),
        duration: 2000,
        position: 'top-left',
      });
      this.info.approve = true
    },
    async register() {
      if (!this.check) {
        this.$notify({
          type: 'warning',
          message: this.$t("register_aggrement_tip"),
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (this.registerLoading) {
        return false
      }
      console.log(this.form)
      if (!this.form.superior || !isAddress(this.form.superior)) {
        this.$notify({
          type: 'warning',
          message: this.$t("center_invite_address_tip"),
          duration: 2000,
          position: 'top-left',
        });
        return false
      }

      if (new BigNumber(this.form.amount).times(this.info.usdtDecimals).comparedTo(this.info.balance) > 0) {
        this.$notify({
          type: 'warning',
          message: this.$t("center_balance_tip"),
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      this.registerLoading = true
      const _data = {
        name: this.form.name,
        tel: this.form.tel,
      }
      const res = await Register(this.form.superior, this.form.amount, _data)
      this.registerLoading = false
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: this.$t("center_approve_cancel"),
          duration: 2000,
          position: 'top-left',
        });
        return false
      }

      this.$notify({
        type: 'success',
        message: this.$t("center_register_success"),
        duration: 2000,
        position: 'top-left',
      });

      const hash = res.transactionHash
      MerchantRegister({
        Address: this.form.account,
        Hash: hash,
        Protocol: this.protocol
      }).then()

      this.setIsRegister(this.form.superior !== '0x0000000000000000000000000000000000000000')
      this.$router.push({ path: "/advertise-create" });

    },
    protocolClick() {
      this.$router.push({ path: `/protocol?type=merchantService` })
    }
  }
}
</script>

<style scoped>
.root {
  overflow-y: scroll;
}

.check_label {
  font-size: 24px;
  font-weight: 400;
  color: #666666;
}

.form {
  padding: 0 32px 32px;
}

.top_item_margin {
  margin-top: 46px;
}

.divier_top {
  background-color: #F5F5F5;
  height: 10px;
}

.btn_left {
  height: 80px;
  flex: 3;
  border: 2px solid #3665B7;
  border-radius: 10px;
  font-size: 32px;
  margin-right: 30px;
  font-weight: 400;
  color: #3665B7;
}

.btn_right {
  height: 80px;
  flex: 5;
  background: #3665B7;
  border-radius: 10px;
  font-size: 32px;
  font-weight: 400;
  color: #FFFFFF;
}

.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.form-item-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.form-item-label-text {
  font-size: 28px;
  font-weight: bold;
  color: #999999;
}


.form-item-suffix {
  margin-right: 24px;
  font-size: 24px;
  color: #000000;
}

.protocol {
  padding: 32px 32px;
  display: flex;
  align-items: center;
}

.protocol-a {
  color: #3665B7;
  font-size: 24px;
}

.btn {
  padding: 0 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tip {
  padding: 32px 32px;
}

.tip-title {
  font-size: 28px;
  font-weight: 600;
}

.tip-item {
  color: rgba(61, 61, 61, 0.5);
  padding-left: 24px;
  margin-top: 12px;
}

.tip_container {
  margin-top: 76px;
  margin-bottom: 50px;
}
</style>